// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {
	--ion-color-primary: #313988;
	--ion-color-primary-rgb: 49, 57, 136;
	--ion-color-primary-contrast: #ffffff;
	--ion-color-primary-contrast-rgb: 255, 255, 255;
	--ion-color-primary-shade: #2b3278;
	--ion-color-primary-tint: #464d94;

	--ion-color-secondary: #4a76b4;
	--ion-color-secondary-rgb: 74, 118, 180;
	--ion-color-secondary-contrast: #ffffff;
	--ion-color-secondary-contrast-rgb: 255, 255, 255;
	--ion-color-secondary-shade: #41689e;
	--ion-color-secondary-tint: #5c84bc;

	--ion-color-green: #40b810;
	--ion-color-green-rgb: 64, 184, 16;
	--ion-color-green-contrast: #000000;
	--ion-color-green-contrast-rgb: 0, 0, 0;
	--ion-color-green-shade: #116f07;
	--ion-color-green-tint: #7be53b;

	--ion-color-blue: #1871e6;
	--ion-color-blue-rgb: 24, 113, 230;
	--ion-color-blue-contrast: #ffffff;
	--ion-color-blue-contrast-rgb: 255, 255, 255;
	--ion-color-blue-shade: #0f2ea0;
	--ion-color-blue-tint: #4cb5e7;

	--ion-color-liteblue: #4ab4c7;
	--ion-color-liteblue-rgb: 74, 180, 199;
	--ion-color-liteblue-contrast: #ffffff;
	--ion-color-liteblue-contrast-rgb: 255, 255, 255;
	--ion-color-liteblue-shade: #18a1b9;
	--ion-color-liteblue-tint: #83dee9;

	--ion-color-magenta: #8b14ce;
	--ion-color-magenta-rgb: 139, 20, 206;
	--ion-color-magenta-contrast: #ffffff;
	--ion-color-magenta-contrast-rgb: 255, 255, 255;
	--ion-color-magenta-shade: #49037b;
	--ion-color-magenta-tint: #ca57f5;

	--ion-color-dark-blue: #110e4a;
	--ion-color-dark-blue-rgb: 17, 14, 74;
	--ion-color-dark-blue-contrast: #ffffff;
	--ion-color-dark-blue-contrast-rgb: 255, 255, 255;
	--ion-color-dark-blue-shade: #0f0c41;
	--ion-color-dark-blue-tint: #29265c;

	--ion-color-gold: #f7ab23;
	--ion-color-gold-rgb: 247, 171, 35;
	--ion-color-gold-contrast: #000000;
	--ion-color-gold-contrast-rgb: 0, 0, 0;
	--ion-color-gold-shade: #b4600e;
	--ion-color-gold-tint: #fcc846;

	--ion-color-grey: #666666;
	--ion-color-grey-rgb: 102, 102, 102;
	--ion-color-grey-contrast: #ffffff;
	--ion-color-grey-contrast-rgb: 255, 255, 255;
	--ion-color-grey-shade: #5a5a5a;
	--ion-color-grey-tint: #757575;

	--ion-color-litegrey: #ccc;
	--ion-color-litegrey-rgb: 204, 204, 204;
	--ion-color-litegrey-contrast: #000000;
	--ion-color-litegrey-contrast-rgb: 0, 0, 0;
	--ion-color-litegrey-shade: #b4b4b4;
	--ion-color-litegrey-tint: #d1d1d1;

	--ion-color-bronze: #a95b0e;
	--ion-color-bronze-rgb: 169, 91, 14;
	--ion-color-bronze-contrast: #ffffff;
	--ion-color-bronze-contrast-rgb: 255, 255, 255;
	--ion-color-bronze-shade: #804a00;
	--ion-color-bronze-tint: #b96727;

	--ion-color-fb: #1377ff;
	--ion-color-fb-rgb: 19, 119, 255;
	--ion-color-fb-contrast: #ffffff;
	--ion-color-fb-contrast-rgb: 255, 255, 255;
	--ion-color-fb-shade: #003d6d;
	--ion-color-fb-tint: #7088be;

	--ion-color-death: #dedede;
	--ion-color-death-rgb: 222, 222, 222;
	--ion-color-death-contrast: #000000;
	--ion-color-death-contrast-rgb: 0, 0, 0;
	--ion-color-death-shade: #c3c3c3;
	--ion-color-death-tint: #e1e1e1;

	--ion-color-text-input-highlight-color-invalid: rgba(235, 16, 61, 1);
	--ion-color-text-input-highlight-color-invalid-rgb: 0, 0, 0;
	--ion-color-text-input-highlight-color-invalid-contrast: #ffffff;
	--ion-color-text-input-highlight-color-invalid-contrast-rgb: 255, 255, 255;
	--ion-color-text-input-highlight-color-invalid-shade: #000000;
	--ion-color-text-input-highlight-color-invalid-tint: #1a1a1a;

	--ion-color-tertiary: #697081;
	--ion-color-tertiary-rgb: 105, 112, 129;
	--ion-color-tertiary-contrast: #ffffff;
	--ion-color-tertiary-contrast-rgb: 255, 255, 255;
	--ion-color-tertiary-shade: #5c6372;
	--ion-color-tertiary-tint: #787e8e;

	--ion-color-success: #4cb38b;
	--ion-color-success-rgb: 76, 179, 139;
	--ion-color-success-contrast: #ffffff;
	--ion-color-success-contrast-rgb: 255, 255, 255;
	--ion-color-success-shade: #439e7a;
	--ion-color-success-tint: #5ebb97;

	--ion-color-warning: #ffc409;
	--ion-color-warning-rgb: 255, 196, 9;
	--ion-color-warning-contrast: #000000;
	--ion-color-warning-contrast-rgb: 0, 0, 0;
	--ion-color-warning-shade: #e0ac08;
	--ion-color-warning-tint: #ffca22;

	--ion-color-danger: #eb103d;
	--ion-color-danger-rgb: 235, 16, 61;
	--ion-color-danger-contrast: #ffffff;
	--ion-color-danger-contrast-rgb: 255, 255, 255;
	--ion-color-danger-shade: #cf0e36;
	--ion-color-danger-tint: #ed2850;

	--ion-color-red: #ac0911;
	--ion-color-red-rgb: 172, 9, 17;
	--ion-color-red-contrast: #ffffff;
	--ion-color-red-contrast-rgb: 255, 255, 255;
	--ion-color-red-shade: #ac0911;
	--ion-color-red-tint: #fe9755;

	--ion-color-dark: #222;
	--ion-color-dark-rgb: 22, 33, 58;
	--ion-color-dark-contrast: #ffffff;
	--ion-color-dark-contrast-rgb: 255, 255, 255;
	--ion-color-dark-shade: #131d33;
	--ion-color-dark-tint: #2d374e;

	--ion-color-medium: #92949c;
	--ion-color-medium-rgb: 146, 148, 156;
	--ion-color-medium-contrast: #000000;
	--ion-color-medium-contrast-rgb: 0, 0, 0;
	--ion-color-medium-shade: #808289;
	--ion-color-medium-tint: #9d9fa6;

	--ion-color-light: #f4f4f4;
	--ion-color-light-rgb: 244, 244, 244;
	--ion-color-light-contrast: #000000;
	--ion-color-light-contrast-rgb: 0, 0, 0;
	--ion-color-light-shade: #d7d7d7;
	--ion-color-light-tint: #f5f5f5;

	--ion-color-facebook: #507cc0;
}

.ion-color-green {
	--ion-color-base: var(--ion-color-green);
	--ion-color-base-rgb: var(--ion-color-green-rgb);
	--ion-color-contrast: var(--ion-color-green-contrast);
	--ion-color-contrast-rgb: var(--ion-color-green-contrast-rgb);
	--ion-color-shade: var(--ion-color-green-shade);
	--ion-color-tint: var(--ion-color-green-tint);
}

.ion-color-blue {
	--ion-color-base: var(--ion-color-blue);
	--ion-color-base-rgb: var(--ion-color-blue-rgb);
	--ion-color-contrast: var(--ion-color-blue-contrast);
	--ion-color-contrast-rgb: var(--ion-color-blue-contrast-rgb);
	--ion-color-shade: var(--ion-color-blue-shade);
	--ion-color-tint: var(--ion-color-blue-tint);
}

.ion-color-red {
	--ion-color-base: var(--ion-color-red);
	--ion-color-base-rgb: var(--ion-color-red-rgb);
	--ion-color-contrast: var(--ion-color-red-contrast);
	--ion-color-contrast-rgb: var(--ion-color-red-contrast-rgb);
	--ion-color-shade: var(--ion-color-red-shade);
	--ion-color-tint: var(--ion-color-red-tint);
}

.ion-color-liteblue {
	--ion-color-base: var(--ion-color-liteblue);
	--ion-color-base-rgb: var(--ion-color-liteblue-rgb);
	--ion-color-contrast: var(--ion-color-liteblue-contrast);
	--ion-color-contrast-rgb: var(--ion-color-liteblue-contrast-rgb);
	--ion-color-shade: var(--ion-color-liteblue-shade);
	--ion-color-tint: var(--ion-color-liteblue-tint);
}

.ion-color-facebook {
	--ion-color-base: var(--ion-color-facebook);
}

.ion-color-magenta {
	--ion-color-base: var(--ion-color-magenta);
	--ion-color-base-rgb: var(--ion-color-magenta-rgb);
	--ion-color-contrast: var(--ion-color-magenta-contrast);
	--ion-color-contrast-rgb: var(--ion-color-magenta-contrast-rgb);
	--ion-color-shade: var(--ion-color-magenta-shade);
	--ion-color-tint: var(--ion-color-magenta-tint);
}

.ion-color-dark-blue {
	--ion-color-base: var(--ion-color-dark-blue);
	--ion-color-base-rgb: var(--ion-color-dark-blue-rgb);
	--ion-color-contrast: var(--ion-color-dark-blue-contrast);
	--ion-color-contrast-rgb: var(--ion-color-dark-blue-contrast-rgb);
	--ion-color-shade: var(--ion-color-dark-blue-shade);
	--ion-color-tint: var(--ion-color-dark-blue-tint);
}

.ion-color-gold {
	--ion-color-base: var(--ion-color-gold);
	--ion-color-base-rgb: var(--ion-color-gold-rgb);
	--ion-color-contrast: var(--ion-color-gold-contrast);
	--ion-color-contrast-rgb: var(--ion-color-gold-contrast-rgb);
	--ion-color-shade: var(--ion-color-gold-shade);
	--ion-color-tint: var(--ion-color-gold-tint);
}

.ion-color-grey {
	--ion-color-base: var(--ion-color-grey);
	--ion-color-base-rgb: var(--ion-color-grey-rgb);
	--ion-color-contrast: var(--ion-color-grey-contrast);
	--ion-color-contrast-rgb: var(--ion-color-grey-contrast-rgb);
	--ion-color-shade: var(--ion-color-grey-shade);
	--ion-color-tint: var(--ion-color-grey-tint);
}

.ion-color-litegrey {
	--ion-color-base: var(--ion-color-litegrey);
	--ion-color-base-rgb: var(--ion-color-litegrey-rgb);
	--ion-color-contrast: var(--ion-color-litegrey-contrast);
	--ion-color-contrast-rgb: var(--ion-color-litegrey-contrast-rgb);
	--ion-color-shade: var(--ion-color-litegrey-shade);
	--ion-color-tint: var(--ion-color-litegrey-tint);
}

.ion-color-bronze {
	--ion-color-base: var(--ion-color-bronze);
	--ion-color-base-rgb: var(--ion-color-bronze-rgb);
	--ion-color-contrast: var(--ion-color-bronze-contrast);
	--ion-color-contrast-rgb: var(--ion-color-bronze-contrast-rgb);
	--ion-color-shade: var(--ion-color-bronze-shade);
	--ion-color-tint: var(--ion-color-bronze-tint);
}

.ion-color-fb {
	--ion-color-base: var(--ion-color-fb);
	--ion-color-base-rgb: var(--ion-color-fb-rgb);
	--ion-color-contrast: var(--ion-color-fb-contrast);
	--ion-color-contrast-rgb: var(--ion-color-fb-contrast-rgb);
	--ion-color-shade: var(--ion-color-fb-shade);
	--ion-color-tint: var(--ion-color-fb-tint);
}

.ion-color-death {
	--ion-color-base: var(--ion-color-death);
	--ion-color-base-rgb: var(--ion-color-death-rgb);
	--ion-color-contrast: var(--ion-color-death-contrast);
	--ion-color-contrast-rgb: var(--ion-color-death-contrast-rgb);
	--ion-color-shade: var(--ion-color-death-shade);
	--ion-color-tint: var(--ion-color-death-tint);
}

.ion-color-text-input-highlight-color-invalid {
	--ion-color-base: var(--ion-color-text-input-highlight-color-invalid);
	--ion-color-base-rgb: var(--ion-color-text-input-highlight-color-invalid-rgb);
	--ion-color-contrast: var(--ion-color-text-input-highlight-color-invalid-contrast);
	--ion-color-contrast-rgb: var(--ion-color-text-input-highlight-color-invalid-contrast-rgb);
	--ion-color-shade: var(--ion-color-text-input-highlight-color-invalid-shade);
	--ion-color-tint: var(--ion-color-text-input-highlight-color-invalid-tint);
}

@media (prefers-color-scheme: dark) {
	/*
   * Dark Colors
   * -------------------------------------------
   */

	/* body {
    --ion-color-primary: #428cff;
    --ion-color-primary-rgb: 66,140,255;
    --ion-color-primary-contrast: #ffffff;
    --ion-color-primary-contrast-rgb: 255,255,255;
    --ion-color-primary-shade: #3a7be0;
    --ion-color-primary-tint: #5598ff;

    --ion-color-secondary: #50c8ff;
    --ion-color-secondary-rgb: 80,200,255;
    --ion-color-secondary-contrast: #ffffff;
    --ion-color-secondary-contrast-rgb: 255,255,255;
    --ion-color-secondary-shade: #46b0e0;
    --ion-color-secondary-tint: #62ceff;

    --ion-color-tertiary: #6a64ff;
    --ion-color-tertiary-rgb: 106,100,255;
    --ion-color-tertiary-contrast: #ffffff;
    --ion-color-tertiary-contrast-rgb: 255,255,255;
    --ion-color-tertiary-shade: #5d58e0;
    --ion-color-tertiary-tint: #7974ff;

    --ion-color-success: #2fdf75;
    --ion-color-success-rgb: 47,223,117;
    --ion-color-success-contrast: #000000;
    --ion-color-success-contrast-rgb: 0,0,0;
    --ion-color-success-shade: #29c467;
    --ion-color-success-tint: #44e283;

    --ion-color-warning: #ffd534;
    --ion-color-warning-rgb: 255,213,52;
    --ion-color-warning-contrast: #000000;
    --ion-color-warning-contrast-rgb: 0,0,0;
    --ion-color-warning-shade: #e0bb2e;
    --ion-color-warning-tint: #ffd948;

    --ion-color-danger: #ff4961;
    --ion-color-danger-rgb: 255,73,97;
    --ion-color-danger-contrast: #ffffff;
    --ion-color-danger-contrast-rgb: 255,255,255;
    --ion-color-danger-shade: #e04055;
    --ion-color-danger-tint: #ff5b71;

    --ion-color-dark: #f4f5f8;
    --ion-color-dark-rgb: 244,245,248;
    --ion-color-dark-contrast: #000000;
    --ion-color-dark-contrast-rgb: 0,0,0;
    --ion-color-dark-shade: #d7d8da;
    --ion-color-dark-tint: #f5f6f9;

    --ion-color-medium: #989aa2;
    --ion-color-medium-rgb: 152,154,162;
    --ion-color-medium-contrast: #000000;
    --ion-color-medium-contrast-rgb: 0,0,0;
    --ion-color-medium-shade: #86888f;
    --ion-color-medium-tint: #a2a4ab;

    --ion-color-light: #222428;
    --ion-color-light-rgb: 34,36,40;
    --ion-color-light-contrast: #ffffff;
    --ion-color-light-contrast-rgb: 255,255,255;
    --ion-color-light-shade: #1e2023;
    --ion-color-light-tint: #383a3e;
  }*/

	/*
   * iOS Dark Theme
   * -------------------------------------------
   */

	/*.ios body {
    --ion-background-color: #000000;
    --ion-background-color-rgb: 0,0,0;

    --ion-text-color: #ffffff;
    --ion-text-color-rgb: 255,255,255;

    --ion-color-step-50: #0d0d0d;
    --ion-color-step-100: #1a1a1a;
    --ion-color-step-150: #262626;
    --ion-color-step-200: #333333;
    --ion-color-step-250: #404040;
    --ion-color-step-300: #4d4d4d;
    --ion-color-step-350: #595959;
    --ion-color-step-400: #666666;
    --ion-color-step-450: #737373;
    --ion-color-step-500: #808080;
    --ion-color-step-550: #8c8c8c;
    --ion-color-step-600: #999999;
    --ion-color-step-650: #a6a6a6;
    --ion-color-step-700: #b3b3b3;
    --ion-color-step-750: #bfbfbf;
    --ion-color-step-800: #cccccc;
    --ion-color-step-850: #d9d9d9;
    --ion-color-step-900: #e6e6e6;
    --ion-color-step-950: #f2f2f2;

    --ion-toolbar-background: #0d0d0d;

    --ion-item-background: #000000;
  }*/

	/*
   * Material Design Dark Theme
   * -------------------------------------------
   */

	/* .md body {
    --ion-background-color: #121212;
    --ion-background-color-rgb: 18,18,18;

    --ion-text-color: #ffffff;
    --ion-text-color-rgb: 255,255,255;

    --ion-border-color: #222222;

    --ion-color-step-50: #1e1e1e;
    --ion-color-step-100: #2a2a2a;
    --ion-color-step-150: #363636;
    --ion-color-step-200: #414141;
    --ion-color-step-250: #4d4d4d;
    --ion-color-step-300: #595959;
    --ion-color-step-350: #656565;
    --ion-color-step-400: #717171;
    --ion-color-step-450: #7d7d7d;
    --ion-color-step-500: #898989;
    --ion-color-step-550: #949494;
    --ion-color-step-600: #a0a0a0;
    --ion-color-step-650: #acacac;
    --ion-color-step-700: #b8b8b8;
    --ion-color-step-750: #c4c4c4;
    --ion-color-step-800: #d0d0d0;
    --ion-color-step-850: #dbdbdb;
    --ion-color-step-900: #e7e7e7;
    --ion-color-step-950: #f3f3f3;

    --ion-item-background: #1e1e1e;

    --ion-toolbar-background: #1f1f1f;

    --ion-tab-bar-background: #1f1f1f;
  }*/
}
