/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

//Fonts --------------------------------------------------
@import url("https://fonts.googleapis.com/css2?family=Mochiy+Pop+P+One&display=swap");

@font-face {
  src: url("https://fonts.googleapis.com/css2?family=Mochiy+Pop+P+One&display=swap");
  font-family: "Roboto", sans-serif;
  font-weight: bold;
}

@font-face {
  font-family: outfit;
  src: url("assets/fonts/Outfit-VariableFont_wght.ttf");
  font-weight: 100 1000;
  font-stretch: 25% 151%;
}

/*Colors*/
.color-primary {
  color: #313988;
}

.color-secondary {
  color: #4a76b4;
}

.color-tertiary {
  color: #697081;
}

.color-success {
  color: #4cb38b;
}

.color-danger {
  color: #eb103d;
}

.color-dark {
  color: #222;
}

.color-light {
  color: #f4f4f4;
}

$magenta-gradient: linear-gradient(0deg,
    var(--ion-color-magenta-shade) 0%,
    var(--ion-color-magenta) 50%,
    var(--ion-color-magenta-tint) 89%);

$liteblue-gradient: linear-gradient(0deg,
    var(--ion-color-liteblue-shade) 0%,
    var(--ion-color-liteblue) 50%,
    var(--ion-color-liteblue-tint) 100%);

$facebook-gradient: linear-gradient(0deg, var(--ion-color-fb-shade), var(--ion-color-fb));

$blue-gradient: linear-gradient(0deg,
    var(--ion-color-blue-shade) 0%,
    var(--ion-color-blue) 50%,
    var(--ion-color-blue-tint) 100%);

$green-gradient: linear-gradient(0deg,
    var(--ion-color-green-shade) 0%,
    var(--ion-color-green) 50%,
    var(--ion-color-green-tint) 100%);

$gold-gradient: linear-gradient(0deg,
    var(--ion-color-gold-shade) 0%,
    var(--ion-color-gold) 50%,
    var(--ion-color-gold-tint) 100%);

$red-gradient: linear-gradient(0deg,
    var(--ion-color-red-shade) 0%,
    var(--ion-color-red) 50%,
    var(--ion-color-red-tint) 100%);

$bronze-gradient: linear-gradient(0deg, var(--ion-color-bronze-shade), var(--ion-color-bronze));

$silver-gradient: linear-gradient(0deg, #868686, #c0c0c0);

.bg-color-green {
  background-color: var(--ion-color-green);
}

.liteblue {
  color: var(--ion-color-liteblue);
}

.magenta {
  color: var(--ion-color-magenta);
}

.green,
.online span .color-green {
  color: var(--ion-color-green);
}

.gold {
  color: var(--ion-color-gold);
}

.red,
.offline span {
  color: var(--ion-color-red);
}

.gray {
  color: #333;
}

.litegrey {
  color: #ccc;
}

.white {
  color: #ffffff;
}

.blue {
  color: var(--ion-color-blue);
}

.bronze {
  color: #b1560f;
}

.silver {
  color: #c0c0c0;
}

.magenta-bg {
  background-color: var(--ion-color-magenta);
}

.liteblue-bg {
  background-color: var(--ion-color-liteblue);
}

.green-bg {
  background-color: var(--ion-color-green);
}

.gold-bg {
  background-color: var(--ion-color-gold);
}

.red-bg {
  background-color: var(--ion-color-red);
}

.blue-bg {
  background-color: var(--ion-color-blue);
}

.litegrey-bg {
  background-color: #ccc;
}

.magenta-gradient {
  --background: transparent;
  background: $magenta-gradient;
}

.liteblue-gradient {
  --background: transparent;
  background: $liteblue-gradient;
}

.facebook-gradient {
  --background: transparent;
  background: $facebook-gradient;
}

.blue-gradient {
  --background: transparent;
  background: $blue-gradient;
}

.green-gradient {
  --background: transparent;
  background: $green-gradient;
}

.gold-gradient {
  --background: transparent;
  background: $gold-gradient;
}

.red-gradient {
  --background: transparent;
  background: $red-gradient;
}

.bronze-gradient {
  --background: transparent;
  background: $bronze-gradient;
}

.silver-gradient {
  --background: transparent;
  background: $silver-gradient;
}

// only used on btn-bg
$large-key-light: radial-gradient(circle at top left, #fff 5%, transparent 9%);
$small-key-light: radial-gradient(circle at top left, #fff 5%, transparent 7%);

.magenta-gradient-btn-bg {
  --background: transparent;
  background: $large-key-light, $magenta-gradient;
}

.liteblue-gradient-btn-bg {
  --background: transparent;
  background: $small-key-light, $liteblue-gradient;
}

.facebook-gradient-btn-bg {
  --background: transparent;
  background: radial-gradient(circle at top left, #fff 2%, transparent 5%), $facebook-gradient;
}

.blue-gradient-btn-bg {
  --background: transparent;
  background: $small-key-light, $blue-gradient;
}

.green-gradient-btn-bg {
  --background: transparent;
  background: $large-key-light, $green-gradient;
}

.gold-gradient-btn-bg {
  --background: transparent;
  background: $large-key-light, $gold-gradient;
}

.red-gradient-btn-bg {
  --background: transparent;
  background: radial-gradient(circle at top left, #fff 5%, transparent 9%), $red-gradient;
}

.bronze-gradient-btn-bg {
  --background: transparent;
  background: radial-gradient(circle at top left, #fff 5%, transparent 9%), $bronze-gradient;
}

.silver-gradient-btn-bg {
  --background: transparent;
  background: radial-gradient(circle at top left, #fff 5%, transparent 9%), $silver-gradient;
}

.magenta-border {
  border-color: var(--ion-color-magenta-shade);
}

.liteblue-border {
  border-color: var(--ion-color-liteblue-shade);
}

.blue-border {
  border-color: #0f2ea0;
}

.green-border {
  border-color: #116f07;
}

.gold-border {
  border-color: var(--ion-color-gold-shade);
}

.silver-border {
  border-color: #c0c0c0;
}

.bronze-border {
  border-color: #b1560f;
}

.gold-inner-shadow {
  box-shadow: 0px 0px 2px 1px var(--ion-color-gold) inset, 0px -5px 9px var(--ion-color-gold-shade) inset,
    2px 3px 10px #000;

  span {
    text-shadow: 2px 2px 5px var(--ion-color-gold-shade);
  }
}

.green-inner-shadow {
  box-shadow: 0px 0px 2px 1px #7be53b inset, 0px -5px 9px #116f07 inset, 2px 2px 10px #000;

  span {
    text-shadow: 2px 2px 5px var(--ion-color-green-shade);
  }
}

.blue-inner-shadow {
  box-shadow: 0px 2px 7px 0px #4cb5e7 inset, 0px -5px 9px #0f2ea0 inset, 2px 2px 10px #000;

  span {
    text-shadow: 2px 2px 5px var(--ion-color-blue-shade);
  }
}

.liteblue-inner-shadow {
  box-shadow: 0px 0px 2px 1px #83dee9 inset, 0px -5px 9px #004f5c inset, 2px 2px 10px #000;

  span {
    text-shadow: 2px 2px 5px var(--ion-color-liteblue-shade);
  }
}

.magenta-inner-shadow {
  box-shadow: 0px 0px 2px 1px var(--ion-color-magenta-tint) inset, 0px -5px 9px var(--ion-color-magenta-shade) inset,
    2px 2px 10px #000;

  span {
    text-shadow: 2px 2px 5px var(--ion-color-magenta-shade);
  }
}

//Avatars --------------------------------------------------
.avatar {
  border-radius: 50%;
  // max-width: 48px;
  // height: auto;
  width: 100%;

  /*Profile Cards*/
  &.avatar-md {
    max-width: 128px;
    border: solid 6px #fff;
  }

  /*Game Lobby and Study*/
  &.avatar-lg {
    max-width: 168px;
  }
}

/*Base Styles*/
html {
  background-color: #0a0923;
}

body {
  margin-top: constant(safe-area-inset-top);
  margin-top: env(safe-area-inset-top);
  overflow-x: hidden;
}

ion-tab-bar {
  margin-bottom: calc(4px + env(safe-area-inset-top));
}

.adBody {
  height: calc(100% - 48px);
  bottom: 0;
}

.font-mochiy * {
  font-family: Mochiy Pop P One;
  font-weight: regular;
}

//Profile --------------------------------------------------
.profile {
  h3 {
    position: absolute;
    bottom: 5px;
    left: 130px;
    color: #fff !important;
    font-size: 20px !important;
  }

  .profile-cover {
    height: 130px;
    overflow: hidden;
    position: relative;
    background-size: cover;

    .gradient {
      position: absolute;
      width: 100%;
      height: 100%;
      background: linear-gradient(to top, rgba(0, 0, 0, 0.65) 0%, rgba(0, 0, 0, 0) 59%),
        linear-gradient(to bottom, rgba(0, 0, 0, 0.65) 0%, rgba(0, 0, 0, 0) 30%);
    }
  }

  .profile-avatar {
    width: 100px;
    height: auto;
    border-radius: 50%;
    margin-top: -65px;
    position: relative;
    z-index: 9;
    border: solid 5px #fff;
  }

  .show-more-btn {
    position: absolute;
    top: 16px;
    right: 5px;
    font-size: 2rem;

    ion-icon {
      fill: rgba(255, 255, 255, 0.8);
    }
  }
}

.status {
  font-size: 10px;
  line-height: 16px;
  color: var(--ion-color-litegrey);

  span {
    font-size: 16px;
  }
}

//Typography --------------------------------------------------
.heading,
.subheading {
  // font-family: k26, "Comic sans", Arial, Helvetica, sans;
  font-size: 32px;
  color: var(--ion-color-gold);
  max-width: 250px;
  margin: 20px auto;
  font-family: "Roboto", sans-serif;
  font-weight: bold;
}

.subheading {
  font-size: 20px;
  color: #fff;
}

.title,
ion-card-title.card-title,
ion-title,
.toolbar-title {
  font-size: 20px;
  color: var(--ion-color-grey);
}

.card p,
.content p {
  color: var(--ion-color-grey);
}

//Achievements Round ProgressBar --------------------------------------------------

.round-progress {
  width: 86px;
  height: 86px;
  margin: 5px auto;
  position: relative;

  .tier1,
  .tier2,
  .tier3,
  .achievement-image {
    position: absolute;
    padding: 2px;
  }

  .achievement-image {
    width: 42px;
    left: 50%;
    margin-left: -21px;
    margin-top: 22px;

    img {
      border-radius: 50%;
    }
  }

  .tier1 {
    width: 56px;
    left: 50%;
    margin-left: -28px;
    margin-top: 15px;
  }

  .tier2 {
    width: 72px;
    left: 50%;
    margin-left: -36px;
    margin-top: 7px;
    opacity: 0.75;
  }

  .tier3 {
    width: 86px;
    height: 86px;
    left: 50%;
    margin-left: -43px;
    opacity: 0.5;
  }
}

//App Defaults --------------------------------------------------

ion-tabs,
ion-content {
  --background: transparent;
  background: transparent;
}

@media (max-width: 799px) {

  app-tabs,
  app-profile-edit,
  app-single-player-lobby,
  app-tourney,
  app-study {
    background: url(assets/imgs/BG_ENEM_GAME01.png) no-repeat center;
    background-size: cover;
  }

  app-single-player-game,
  app-game-history-questions,
  app-daily-challenge,
  app-study-detail {
    background: url(assets/imgs/BG_ENEM_GAME02.png) no-repeat center;
    background-size: cover;
  }
}

@media (min-width: 426px) and (max-width: 800px) {

  app-tabs,
  app-profile-edit,
  app-single-player-lobby,
  app-tourney,
  app-study {
    background: url(assets/imgs/BG_ENEM_GAME01_1920X1080.png) round;
    background-size: cover;
  }

  app-single-player-game,
  app-game-history-questions,
  app-daily-challenge,
  app-study-detail {
    background: url(assets/imgs/BG_ENEM_GAME02.png) no-repeat center;
    background-size: cover;
  }
}

@media (min-width: 800px) {

  app-tabs,
  app-profile-edit,
  app-single-player-lobby,
  app-tourney,
  app-study {
    background: url(assets/imgs/BG_ENEM_GAME01_2970x1673.png) round;
  }

  app-single-player-game,
  app-game-history-questions,
  app-daily-challenge,
  app-study-detail {
    background: url(assets/imgs/BG_ENEM_GAME02.png) no-repeat center;
    background-size: cover;
  }
}

.page-wrapper {
  align-items: center;
  height: 100%;
  display: flex;

  .align-center-page {
    margin: 0 auto;
  }
}

.alert-message {
  padding-bottom: 0px !important;
}

.content-container {
  max-width: 810px;
  margin: 0 auto;
  height: 100%;
}

.bar-button-menutoggle {
  display: inline-block;
}

.popover-content {
  width: 80% !important;
  max-width: 540px;
  background: transparent !important;
  box-shadow: none !important;
}

ion-menu {
  .menu-inner {
    width: 100%;
    background-color: #151a32 !important;
  }

  ion-icon {
    fill: #4e5b9b;
  }

  ion-fab ion-icon {
    fill: #fff;
  }

  .scroll-content,
  .item {
    background-color: #151a32;
    color: #fff;
    // font-family: k26, "Comic sans", Arial, Helvetica, sans;
    font-family: "Roboto", sans-serif;
    overflow: hidden;
  }

  .item-inner {
    border-color: #1d253f !important;
  }

  .list-ios>.item-block:first-child {
    border: none;
  }

  .profile {
    border-bottom: 2px solid #1d253f;
  }
}

.ion-badges {
  width: calc(100% - 100px);
  margin-top: -35px;
  margin-left: 115px;
  height: 40px;
}

.fb-connect {
  position: absolute;
  right: 10px;
  bottom: 10px;
}

ion-badge {
  font-family: "Roboto", "Helvetica Neue", sans-serif;
  background-color: transparent !important;
  border-radius: 2px !important;
  font-size: 11px;

  &.likes,
  &.time {
    border: solid 1px var(--ion-color-liteblue);
    color: #cfd4f9;
  }

  &.life {
    border: solid 1px var(--ion-color-red);
    transition: color linear 200ms;
    color: var(--ion-color-red);

    ion-icon[color="death"] {
      fill: var(--ion-color-death);
    }

    ion-icon.icon-md-death,
    ion-icon.icon-ios-death {
      fill: var(--ion-color-death);
      animation: death 1s ease-in-out;
      animation-fill-mode: initial;
    }
  }

  &.record {
    border: solid 1px var(--ion-color-magenta);
    color: #dec5bd;
  }
}

/*Life lost animation*/
@keyframes death {
  0% {
    transform: scale3d(1, 1, 1);
  }

  100% {
    transform: scale3d(10, 10, 10);
    opacity: 0;
  }
}

.ion-card-full {
  margin: 0;
  width: 100% !important;
}

#user-info {
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  align-content: stretch;
  justify-content: center;
  align-items: baseline;

  &.info-md {
    height: 11.815em;
  }

  &.info-ios {
    // height: 9.815em;
    height: auto;
  }

  .row {
    // flex: 1;
    display: flex;
    flex-flow: row nowrap;
    align-content: space-between;
    justify-content: space-around;
    align-items: inherit;
    width: 100%;
    max-width: 465px;
    margin: auto;
  }

  &>.row {
    align-items: center;
  }

  .row:nth-child(3) {
    justify-content: space-between;
  }

  .config {
    height: 50px;
    width: 55px;

    border: none;

    --background: url(assets/icon/config.png) no-repeat round;
    --background-activated: url(assets/icon/config.png) no-repeat round;
    --box-shadow: none;
  }

  .daily {
    height: 50px;
    width: 55px;

    border: none;
    // TODO temporary fix
    --background: transparent;
    --background-activated: transparent;
    // --background: url(assets/icon/daily.png) no-repeat round;
    // --background-activated: url(assets/icon/daily.png) no-repeat round;
    --box-shadow: none;
  }

  span {
    ion-chip {
      width: 80px;
      height: 20px;
      font-weight: 700;
      font-family: "Outfit";
    }

    &.coin {
      ion-chip {
        text-align: right;
        background-color: #fff;
        border: 2px solid #4cb5e7;
        color: #000;
        justify-content: flex-end;
      }

      img {
        position: relative;
        top: 8px;
        left: 16px;
        z-index: 9;
        width: 24px;
      }
    }

    &.lv {
      ion-chip {
        background: linear-gradient(3deg, #163b90 0%, #5ba7fe 75%, #4cb5e7 86%);
        border: 1px solid black;
        color: #fff;
        justify-content: center;
      }

      img {
        position: relative;
        top: 6px;
        left: 16px;
        z-index: 9;
        width: 32px;
      }

      ion-chip.xp {
        span {
          font-family: "Roboto", "Comic sans", Arial, Helvetica, sans;
        }
      }
    }
  }

  .avatar-container {
    height: 85px;

    .avatar {
      width: 85px;
      height: 85px;
      top: -8px;

      border-radius: 50%;
      border: solid 1px transparent;
      position: relative;

      padding: 12px;
      background: radial-gradient(transparent 22px, #01016e 30px, transparent 31px),
        radial-gradient(transparent 40px, #01016e 71%) center,
        linear-gradient(0deg, #0f2ea0 0, #1871e6 49%, #4cb5e7 100%);
    }

    .progress-position {
      position: relative;
      top: -97px;
      width: 93%;
      left: 3px;
    }
  }
}

ion-avatar {
  min-width: 30px !important;
  min-height: 30px !important;

  img {
    width: 30px !important;
    height: 30px !important;
  }
}

.completed-objectives-marker {
  position: absolute;
  background-color: var(--ion-color-red);
  color: #fff;
  font-size: 6px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  line-height: 13px;
  text-align: center;
  right: -3px;
  top: 5px;
}

.stylized {
  font-family: "Roboto", "Comic sans", Arial, Helvetica, sans;
  border: none;
  box-shadow: inset 0 0 1px #ccc, 0 0 2pxbackgroundbackground #f2f2f2;
  border-top: solid 1px rgba(255, 255, 255, 0.7);
  text-shadow: 0 1px 0 #666;
}

ion-header {
  font-family: "Roboto", "Comic sans", Arial, Helvetica, sans;
}

ion-content,
.content {
  background-color: transparent;
}

.header:after {
  background-image: none;
}

ion-header {
  .toolbar {
    min-height: 42px;

    .toolbar-background {
      background-color: transparent;
      background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.2));
    }

    .toolbar-title {
      color: #fff;
    }
  }
}

ion-footer#footer-nav {
  .toolbar {
    min-height: 42px;
    padding: 0;
    overflow: visible;
    contain: unset;

    ion-grid {
      padding: 0;

      ion-col {
        height: 56px;
        padding: 0;

        .toolbar-labels {
          font-size: 10px;
          color: var(--ion-color-liteblue);
          position: absolute;
          bottom: 3px;
          text-align: center;
          width: 100%;
        }
      }

      .button {
        height: 100%;
        margin: 0;

        &.play {
          width: 48px;
          height: 48px;
          position: absolute;
          bottom: 10px;
          left: 50%;
          margin-left: -24px;
          border-radius: 50%;
          box-shadow: 0 0 5px #000;

          img {
            width: 26px;
            height: auto;
            max-width: none;
          }
        }

        ion-icon {
          font-size: 22px;
          fill: #fff;
        }

        &.current {
          border-top: solid 2px var(--ion-color-liteblue);
        }
      }
    }

    .toolbar-background {
      background-color: transparent;
      background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.2));
    }
  }
}

.progress {
  border-radius: 15px;
  overflow: hidden;
  width: 80%;
  margin: 0 auto;
  border-color: #ccc;
  color: #000;
  height: 28px;
  background-color: #000083;
  overflow: visible;

  .progress-bar {
    //width: 46%;
    height: 28px;
    border: 2px solid #b1784b;
    border-radius: 15px;
    text-align: right;
    font-size: 12px;
    color: #000;
    padding-right: 5px;
    line-height: 20px;
    @extend .gold-gradient;
  }
}

.rounded {
  border-radius: 50%;
}

.profile-popover-card .popover-content {
  width: 300px;
}

ion-backdrop {
  opacity: 0.5;
}

//Popover --------------------------------------------------

ion-modal.fullscreen {
  --width: 100%;
  --height: 100%;
  --border-radius: 0;
}

ion-popover {
  &.big-popover {
    &::part(content) {
      margin: auto;
      width: 100% !important;
      background: transparent;
      box-shadow: none;
      overflow: hidden;
      top: calc(50vh - 331px) !important;
      left: calc(50vw - 182px) !important;
    }

    config-modal,
    app-death-reward,
    app-single-player-game-results,
    page-avatars-covers,
    info-popover {
      background: url(./assets/imgs/background_popover_lightblue.svg) no-repeat round;
      width: 100%;
      height: 613px;
      overflow: visible;
    }

    bug-report-popover,
    page-request-xtra-info {
      background: url(./assets/imgs/background_popover_golden.svg) no-repeat round;
      width: 100%;
      height: 600px;
    }

    page-ranking-popover {
      background: url(./assets/imgs/tourney/ranking-container.svg) no-repeat round;
      width: 100%;
      height: 600px;
      margin: 0 auto;
    }

    app-single-player-interval {
      width: 355px;
      height: 500px;
      overflow: visible;

      ion-content::part(scroll) {
        height: 100%;
      }
    }
  }

  &.small-popover {
    &::part(content) {
      margin: auto;
      width: 300px;
      background: transparent;
      box-shadow: none;
      overflow: hidden;
      top: calc(50vh - 262px) !important;
      left: calc(50vw - 145px) !important;
    }

    prime-modal,
    prime-financial-renew {
      background: url(./assets/imgs/background_popover_magenta.svg) no-repeat round;
      width: 100%;
      height: 448px;
    }
  }

  &.red-popover {
    &::part(content) {
      margin: auto;
      width: 300px !important;
      background: transparent;
      box-shadow: none;
      overflow: hidden;
      top: calc(50vh - 172px) !important;
      left: calc(50vw - 150px) !important;
    }

    abandon-modal,
    app-delete-account {
      background: url(./assets/imgs/background_popover_red.svg) no-repeat round;
      width: 100%;
      height: 300px;
    }
  }
}

// EXTRA SMALL PHONE
@media (max-width: 360px) or (max-height: 665px) {
  ion-popover {
    &.big-popover {
      &::part(content) {
        top: calc(50vh - 246px) !important;
        left: calc(50vw - 129px) !important;
      }

      config-modal,
      page-avatars-covers,
      app-death-reward {
        height: 420px;
      }

      app-single-player-game-results {
        height: 535px;
        background-size: 253px;
        background-repeat: no-repeat;
      }

      bug-report-popover {
        background-size: 255px;
        height: 526px;
      }
    }


    &.small-popover {
      &::part(content) {
        top: calc(50vh - 228px) !important;
        left: calc(50vw - 127px) !important;
      }

      prime-modal,
      prime-financial-renew {
        width: 100%;
        height: 400px;
      }
    }
  }
}

//Buttons --------------------------------------------------
.bar-button-default,
.bar-button-default:hover:not(.disable-hover),
.bar-button-clear-default:hover:not(.disable-hover),
.bar-button-default:hover:not(.disable-hover) {
  color: #b1c8e8;
}

ion-icon[name="arrow-back"] {
  font-size: 24px;
  min-width: 44px;
}

ion-header {
  // background: linear-gradient(#11215b 7%, #153d92 31%, #2a8bed 77%, #10215a 152%);

  ion-toolbar {
    background: transparent url(./assets/imgs/header/head_background.svg) top no-repeat border-box;
  }

  &.header-md::after {
    background-image: none;
  }

  ion-buttons {
    ion-button[menuToggle] {
      min-width: 28px;
      margin-right: 2px;
    }

    ion-button:hover {
      --background-hover: transparent;
    }
  }
}

.content ion-buttons {
  //min-width: 250px;
  margin: 0 auto;
}

ion-buttons {
  &[end] ion-button {
    display: inline-block !important;
  }
}

ion-toolbar {
  height: 187px;
  margin-bottom: -103px;
  --background: transparent;
  --border-width: 0 !important;
}

ion-toolbar ion-buttons {
  max-width: 100%;
}

.btn {
  border-radius: 50px;
  font-family: Mochiy Pop P One, "Roboto", "Comic sans", Arial, Helvetica, sans;
  padding: 5px 20px;
  border-style: solid;
  color: #fff;
  border-width: 1px;
  text-transform: unset;
  font-size: 19px !important;
  cursor: pointer;
  position: relative;
  max-width: 250px;
  margin-right: auto !important;
  margin-left: auto !important;
}

//Gameplay -------------------------------------------
.question-text {
  p {
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 12px;
  }
}

.game-container {
  position: relative;
  height: 100%;
}

.card-ios .toolbar-content-ios {
  ion-title {
    padding: 0 0 0 15px;
    position: relative;

    .toolbar-title {
      text-align: left;
    }
  }
}

.question-text,
.question-text p {
  margin-bottom: 20px;
  line-height: 24px !important;
  color: #333 !important;
  font-size: 14px !important;
}

.question-answers {
  padding-bottom: 80px;

  ion-button {
    width: 100%;
    height: 100%;

    ion-grid {
      padding: 0;

      ion-row {
        border: solid 1px #ccc;
        margin-bottom: 5px;
        border-radius: 10px;
        position: relative;

        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: stretch;
        justify-content: space-around;
        align-items: center;

        .answer {
          padding: 15px;
          font-size: 12px;

          p {
            color: #666;
            font-family: "outfit", sans-serif;
            font-size: 14px;
            font-weight: 600;
            white-space: normal;
            text-transform: initial;
          }
        }

        ion-fab {
          ion-icon {
            font-size: 16px;
          }

          &[top] {
            top: -20px;
          }

          &[right] {
            right: -20px;
          }
        }
      }
    }

    .answer-button {
      //border-right: solid 1px #ccc;
      padding: 0;

      div {
        height: 100%;
        margin: 0;
      }
    }

    .answer-selected {
      border-color: var(--ion-color-liteblue);
    }

    .answer-correct {
      border-color: var(--ion-color-green);
    }

    .answer-wrong {
      border-color: var(--ion-color-red);
    }
  }
}

.answer-button ion-button {
  box-shadow: none;
  //background: linear-gradient(#ccc, #aaa);
  color: #fff;
  --padding-start: 0;
  --padding-end: 0;
}

.answer-selected {
  border-color: var(--ion-color-liteblue);
  // ion-button {
  //   background: linear-gradient(0deg, #2577b8, #6d95cb);
  // }
}

.answer-correct {
  border-color: var(--ion-color-green);
  // ion-button {
  //   background: linear-gradient(0deg, #40a048, #7fb775);
  // }
}

.answer-wrong {
  border-color: var(--ion-color-red);
  // ion-button {
  //   background: linear-gradient(0deg, #be1e2d, #cf6758);
  // }
}

#suit-icon {
  margin-right: 10px;
  border-radius: 50%;
  width: 42px;
  height: 42px;
  background-position: center center;
  background-size: cover;
}

//    DISCIPLINES IMAGES

.discipline-MAT {
  background-image: url(assets/imgs/disciplines/ce.svg);
}

.discipline-CHU {
  background-image: url(assets/imgs/disciplines/ch.svg);
}

.discipline-CDN {
  background-image: url(assets/imgs/disciplines/cn.svg);
}

.discipline-LEC {
  background-image: url(assets/imgs/disciplines/lc.svg);
}

.discipline-ING {
  background-image: url(assets/imgs/disciplines/lc.svg);
}

.discipline-ESP {
  background-image: url(assets/imgs/disciplines/lc.svg);
}

@media (min-width: 768px) {
  ion-menu {
    width: 450px;
  }

  #menu {
    left: auto;
  }

  #obj {
    right: auto;
  }
}

@media (min-width: 452px) {
  page-game-history-questions {
    ion-card .scroll-content {
      padding: 30px !important;
    }
  }

  .question-text {
    p {
      font-size: 18px;
      line-height: 26px;
    }
  }

  .question-answers {
    .answer {
      p {
        font-size: 14px;
      }
    }
  }
}

.transparent-modal {
  --background: transparent;
  --box-shadow: none;
}

//Maintenance and Update -------------------------------------------

.maintenance {
  color: #fff;
  display: flex;
  align-items: center;
  background-image: url("assets/imgs/maintenance.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;

  &.update {
    background-image: url("assets/imgs/BG_ENEM_GAME01.png");
  }

  .maintenance-content {
    h2 {
      font-family: "Roboto", "Comic sans", Arial, Helvetica, sans;
      font-size: 32px;
    }

    p {
      color: #fff !important;
    }

    max-width: 80%;
    margin: 0 auto;
  }

  .lg-icon {
    font-size: 256px;
  }
}
